
import Api from "@/lib/api";
import { Rq } from "@/lib/amdt";
import Vue, { PropType } from "vue";
import { DataOptions } from "vuetify";
import IPermission from "@/lib/interfaces/permission";
import IPermissionGroup from "@/lib/interfaces/permission-group";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ADataTable from "@/components/elements/a-data-table.vue";
import ACard from "@/components/elements/a-card.vue";
import ASelectStatus from "@/components/elements/a-select-status.vue";

export default Vue.extend({
  name: "PermissionsList",

  components: {
    vAlertMessage,
    ABtn,
    ATextField,
    ADataTable,
    ACard,
    ASelectStatus,
  },

  props: {
    permission: {
      type: Object as PropType<IPermission>,
      required: true,
    },

    forceRefresh: {
      type: Number as PropType<number>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      ret_id: 1,
      ret_msg: "",

      total: 0,
      search: "",
      desativado: "0",

      options: {} as DataOptions,
      permissions: new Array<IPermission>(),
      permissionsGroups: new Array<IPermissionGroup>(),

      headers: [
        { text: "Descrição", value: "descricao" },
        { text: "Grupo", value: "cGroup", sortable: false },
        { text: "Identificador", value: "identificador" },
        { text: "Permissões", value: "cPermissions" },
        { text: "Status", value: "cDesativado", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],
    };
  },

  watch: {
    forceRefresh() {
      this.getPermissions().then();
    },

    options: {
      deep: true,
      handler() {
        this.getPermissions().then();
      },
    },
  },

  computed: {
    cPermissions(): Array<Record<string, unknown>> {
      return this.permissions.map((permission) => {
        const permissions = [];
        if (permission.leitura === "1") permissions.push("Leitura");
        if (permission.edicao === "1") permissions.push("Edição");
        if (permission.escrita === "1") permissions.push("Escrita");
        if (permission.remocao === "1") permissions.push("Remoção");
        if (permissions.length === 0) permissions.push("N/A");

        return {
          ...permission,

          cGroup:
            this.permissionsGroups.find(
              (group) => group.id === permission.id_permissao_grupo
            )?.descricao ?? permission.id_permissao_grupo,

          cPermissions: permissions.join(", "),
          cDesativado: permission.desativado === "0" ? "Ativo" : "Inativo",
        };
      });
    },
  },

  mounted() {
    this.getPermissionsGroups().then();
  },

  methods: {
    async openPermission(id: string) {
      const permission = this.permissions.find(
        (permission) => permission.id === id
      );
      this.$emit("update:permission", { ...permission });
    },

    async getPermissions() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("permissao.read", {
          search: this.search,
          desativado: this.desativado,
          itemsPerPage: this.options.itemsPerPage.toString(),
          page: this.options.page.toString(),
          sortBy: this.options.sortBy[0] ?? "id",
          sortOrder: this.options.sortDesc[0] ? "DESC" : "ASC",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.total = Number(rsp.params["total"]);
        this.options.itemsPerPage = Number(rsp.params["itemsPerPage"]);
        this.options.page = Number(rsp.params["page"]);

        this.permissions = rsp.getTable("permissao")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getPermissionsGroups() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("permissao_grupo.read", {
          itemsPerPage: "-1",
          page: "1",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.permissionsGroups =
          rsp.getTable("permissao_grupo")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
