
import Vue from "vue";
import PermissionsList from "@/components/subviews/permissions/permissions-list.vue";
import PermissionsMaintenance from "@/components/subviews/permissions/permissions-maintenance.vue";
import IPermission from "@/lib/interfaces/permission";

export default Vue.extend({
  name: "Permissions",

  components: { PermissionsList, PermissionsMaintenance },

  data() {
    return {
      ret_id: 1,
      ret_msg: "",

      refresh: 0,

      currentTab: 0,

      permission: {} as IPermission,
    };
  },
});
