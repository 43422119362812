
import Api from "@/lib/api";
import Vue, { PropType } from "vue";
import { Rq, Table } from "@/lib/amdt";
import VForm from "@/lib/types/v-form";
import WorgError from "@/lib/worg-error";
import IPermission from "@/lib/interfaces/permission";
import { required, maxLength } from "@/lib/validations";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import IPermissionGroup from "@/lib/interfaces/permission-group";
import ABtnDialogConfirm from "@/components/elements/a-btn-dialog-confirm.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ASwitch from "@/components/elements/a-switch.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ACard from "@/components/elements/a-card.vue";
import AAutocomplete from "@/components/elements/a-autocomplete.vue";

export default Vue.extend({
  name: "PermissionsMaintenance",

  components: {
    vAlertMessage,
    ABtnDialogConfirm,
    ABtn,
    ASwitch,
    ATextField,
    ACard,
    AAutocomplete,
  },

  props: {
    permission: {
      type: Object as PropType<IPermission>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      valid: false,
      ret_id: 1,
      ret_msg: "",

      permissionsGroups: new Array<IPermissionGroup>(),

      dataPermission: {
        id: "",
        id_permissao_grupo: "",
        descricao: "",
        identificador: "",
        leitura: "0",
        edicao: "0",
        escrita: "0",
        remocao: "0",
        desativado: "0",
      } as IPermission,
    };
  },

  watch: {
    permission: {
      deep: true,
      handler() {
        this.ret_id = 1;
        this.ret_msg = "";
        this.dataPermission = { ...this.permission };
      },
    },
  },

  computed: {
    cTextSave(): string {
      return this.dataPermission.id ? "Alterar" : "Salvar";
    },

    form(): VForm {
      return this.$refs.form as VForm;
    },
  },

  mounted() {
    this.getPermissionsGroups();
  },

  methods: {
    required,
    maxLength,

    async evOnSubmit() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("permissao.write");

        const tblPermissao = new Table("permissao");

        const obj = {
          ...this.dataPermission,
          id: this.dataPermission.id || "default",
        };
        tblPermissao.setColsFromObject(obj);
        tblPermissao.addRowsFromObject(obj);

        rq.addTable(tblPermissao);

        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;

          const errorName = WorgError.getErrorByCode(this.ret_id).name;
          if (errorName === "VldError") this.form.validate();
          else if (errorName === "ErrDupEntry")
            this.ret_msg = "Já existe uma permissão com esse nome";

          return;
        }

        this.ret_msg = `Permissão ${
          this.dataPermission.id ? "atualizada" : "cadastrada"
        } com sucesso`;

        this.dataPermission.id = rsp.ret_id.toString();

        this.$emit("updated");
      } catch (error) {
        console.error(error);
      } finally {
        this.form.resetValidation();
        this.loading = false;
      }
    },

    clearForm() {
      this.dataPermission = {
        id: "",
        id_permissao_grupo: "",
        descricao: "",
        identificador: "",
        leitura: "0",
        edicao: "0",
        escrita: "0",
        remocao: "0",
        desativado: "0",
      };

      this.form.resetValidation();
    },

    async getPermissionsGroups() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("permissao_grupo.read", {
          itemsPerPage: "-1",
          page: "1",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.permissionsGroups =
          rsp.getTable("permissao_grupo")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
